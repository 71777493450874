.wrapper{
    background: #453483;
    height: 80px;
    display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
}
.header_left {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .title {
    font-size: 24px;
  }
  
  .header_right {
    display: flex;
    align-items: center;
  }
  
  .profile_icon {
    width: 40px;
    height: 40px;
  }
  .modal_size {
    width: 0 !important;
    height: 0 !important;
  }
  .modal_top_right {
    position: fixed !important;
    top: 70px !important; /* Adjust the top position as needed */
    right: 10px; /* Adjust the right position as needed */
    margin: 0 !important;
    transform: none;
    min-width: 150px;
    z-index: 1050; /* Ensure it appears above other content */
  }
  .modal_top_right p {
    color: red;
    cursor: pointer;
  }
 