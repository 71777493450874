.thickwallet-grid-container {
    margin: 0 auto;
    width: 100%;
    background: #bebcbc;
  }
  
 .thickwallet-grid-container .grid {
    display: flex;
    flex-wrap: wrap;
  }
  
 .thickwallet-grid-container .card {
    flex: 0 0 calc(25% - 20px);
    /* width: 250px; */
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  .thickwallet-grid-container .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .thickwallet-grid-container .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .thickwallet-grid-container .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 600px) {
    .thickwallet-grid-container .card {
      flex: 0 0 calc(50% - 20px);
      margin: 10px;
      padding: 10px;
    }
  }