.wrapper{
    background: #f4f1ed;
    border: solid 1px rgb(185, 175, 175);;
}
.feature_head{
    padding: 5px;
    background: #eff6f9;
    font-size: 25px;
    text-align: center;
}
.search{
    padding: 9px;
    background: #ebdede;
}
.option{
    padding: 3px;
    border-bottom: solid 1px rgb(212, 193, 193);
}

.option button:first-child{
    pointer-events: none;
}
.option button:last-child{
float: right;
background: rgb(7, 144, 30);
    color: white;
}
.added_stocks ul{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}
.added_stocks ul li{
    padding: 7px;
    border-bottom: solid 1px rgb(185, 175, 175);
}
.added_stocks ul li button{
    float: right;
    background: red;
    color: white;
}
.list_style{
    list-style-type: none;
    padding-left: 0;
}
.list_style li{
    border: solid 1px #d0c1c1;
}
.list_title{
    padding: 7px;
}
.list_title button{
    float: right;
}