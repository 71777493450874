.wrapper{
    background: white;
    border: solid 1px red;
}
.feature_head{
    padding: 5px;
    background: #eff6f9;
    font-size: 25px;
    text-align: center;
}
.filter_section{
 background: yellow;
 padding-bottom: 5px;
}
.centered_button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
.closing_style input{
    display: inline-block;
    margin-left: 4px;
}