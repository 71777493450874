.container {
    background: rgb(237, 229, 229);
    margin: 0 auto;
    padding: 5px;
}

.form_wrapper{
    width: 90% !important;
    margin: 0 auto;
}
.password_rules{
    font-size: 12px;
    list-style-type: circle;
    color: red;
    font-weight: 600;
}
.color_green{
    color: green !important;
}
.form_error{
    color: red;
    font-size: 12px;
    font-weight: 500;
}