.wrapper{
    background: #d3dad9;
    border: solid 1px;
   
    padding-bottom: 10px;
}

.iconImage{
    height: 100px;
    width: 100px;
    margin: 0 auto;
}
.card{
    cursor: pointer;
    background-color: brown;
    color: whitesmoke;
}
.card:hover{
    opacity: 0.6;
}
.dashboard_title{
    padding: 5px;
    text-align: center;
    border: solid 1px grey;
}